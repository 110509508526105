import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';

Vue.use(VueLocalStorage);

// eslint-disable-next-line import/prefer-default-export
export const localStorage = {
  isDashboardTableView: {
    type: Boolean,
    default: false,
  },
  tourOfDashboardFinished: {
    type: Boolean,
    default: false,
  },
  tourOfDashboardItemFinished: {
    type: Boolean,
    default: false,
  },
  tourOfPageFinished: {
    type: Boolean,
    default: false,
  },
};
