import gql from 'graphql-tag';

export default gql`
query getGroupDataByGroupId($groupId: Int!) {
  groups_by_pk(group_id: $groupId) {
    group_id
    domain
    slug
    name
    settings
    root_folder_id
    groups_privileges {
      value
      privilege {
        handle
      }
    }
  }
  privileges(where: {scope: {_eq: "groups"}}) {
    handle
    privilege_id
    default_value
  }
}
`;
