import gql from 'graphql-tag';

export default gql`
query getUserByEmail($email: String!) {
  users(where: {
    email: {_eq: $email}
  }) {
    user_id
    email
    sys_admin
    full_name
    deactivated
    email_confirmed
  }
}
`;
