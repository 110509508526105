import gql from 'graphql-tag';

export default gql`
{
  itemUrlWindow @client {
    id
    openItemUrlWindow
    itemPath
  }
}
`;
