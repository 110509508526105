import Vue from 'vue';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import 'froala-editor/js/plugins.pkgd.min';

Vue.use(VueFroala);
