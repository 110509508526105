<template>
  <b-overlay :show="$apollo.loading" rounded="sm" variant="dark">
    <modal-suspended-account
      v-if="userObject && disableAccount === true"
      :group-id="groupId"
      :user-id="userObject.userId"
      :is-sys-admin="userObject.isSysAdmin"
    ></modal-suspended-account>
    <modal-incompatible-item
      v-else-if="currentItemObject && currentItemObject.incompatibleItem
        && userObject && !userObject.isSysAdmin"
      :item-name="incompatibleItemName"
      :item="currentItemObject"
      :modal-for-detail-page="true"
    ></modal-incompatible-item>
    <team-dashboard
      v-else-if="!userObject.isSysAdmin
        && userObject.isGroupUser === false
        && $route.fullPath === '/'
        && allItemsIdsOfTeam
        && accessToRootGroup === false"
      :team-ids="userObject.teamIdsOfUser"
      :user-id="userObject.userId"
      :group-id="groupId"
      :teams-items="allItemsIdsOfTeam"
      @refresh-items-query="$apollo.queries.allItemsIdsOfTeam.refetch();"
    >
    </team-dashboard>
    <div v-else-if="isGroupUser
    || $route.fullPath !== '/'
    || accessToRootGroup === true
    || userObject.isSysAdmin"
    >
      <div class="items-wrapper" :class="(currentItemObject) ? currentItemObject.type : ''">
        <page-item
          v-if="currentItemObject
          && currentItemObject.type === 'page'
          && groupObject
          && !currentItemObject.incompatible_item"
          :user-id="userObject.userId"
          :page-id="currentItemObject.id"
          :item-path="currentItemObject.itemPath"
          :privileges="currentItemObject.privilegesForItem"
          :item-name="currentItemObject.name"
          :is-sys-admin="userObject.isSysAdmin"
          :language="userObject.language"
          :group="groupObject"
        ></page-item>
        <dashboard
          v-else-if="currentItemObject"
          :current-item="currentItemObject"
          :user-object="userObject"
          :all-available-privileges="allAvailablePrivileges"
          :group-id="groupId"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import graphQL queries
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import GetAllItemsOfTeamInGroup from '@/graphQlQueries/queries/getAllItemsOfTeamInGroup';
import GetGroupDataByGroupId from '@/graphQlQueries/queries/getGroupDataByGroupId';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import GetItemFromUrl from '@/mixins/getItemFromUrl';

export default {
  name: 'ItemsWrapper',
  components: {
    ModalIncompatibleItem: () => import('@/components/modals/ModalIncompatibleItem.vue'),
    ModalSuspendedAccount: () => import('@/components/modals/ModalSuspendedAccount.vue'),
    Dashboard: () => import('@/components/dashboard/Dashboard.vue'),
    PageItem: () => import('@/components/PageItem.vue'),
    TeamDashboard: () => import('@/components/dashboard/TeamDashboard.vue'),
  },
  mixins: [UserDataAndAccess, GetItemFromUrl],
  props: {
    parametersOfUrl: { type: String },
  },
  data() {
    return {
      groupObject: null,
      allItemsIdsOfTeam: null,
      accessToRootGroup: false,
    };
  },
  computed: {
    incompatibleItemName() {
      if (this.currentItemObject.incompatibleItem) {
        if (this.currentItemObject.type === 'project') {
          return this.currentItemObject.name;
        }
        if (this.currentItemObject.type === 'page') {
          return this.currentItemObject.parentProjectName;
        }
      }
      return null;
    },
  },
  watch: {
    // if there is no group id for the user go to setup
    groupId(val) {
      if (val === false) {
        this.$router.push('/webmag-setup').catch((err) => {
          throw new Error(`Routing Error occurred! ${err}.`);
        });
      }
    },
    currentItemObject(val) {
      if (val) {
        console.log('current item', val);
        this.setTypeOfPageInApolloCache(val.type);
      } else if (this.$route.path === '/') {
        this.setTypeOfPageInApolloCache('dashboard');
      }
    },
  },
  apollo: {
    groupObject: {
      query: GetGroupDataByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups_by_pk !== null) {
          this.disableAccount = (data.groups_by_pk.settings)
            ? data.groups_by_pk.settings.disableAccount
            : false;
        }
        return data.groups_by_pk;
      },
      skip() {
        return !this.groupId;
      },
    },
    // query for the team view!!!
    allItemsIdsOfTeam: {
      query: GetAllItemsOfTeamInGroup,
      variables() {
        return {
          teamIdArray: this.userObject.teamIdsOfUser,
          groupId: this.groupId,
        };
      },
      update(data) {
        this.checkForRootFolder(data.teams_items);
        const checkSuccessful = this.checkForExistingTeamItemsInCurrentItemPath(data.teams_items);
        // was passiert hier?
        console.log('checkSuccessful', checkSuccessful);
        console.log('this.$route.fullPath', this.$route.fullPath);
        if (
          this.$route.fullPath !== '/'
          && !checkSuccessful
          && !this.userObject.isSysAdmin
        ) {
          console.log('this.$route.fullPath', this.$route.fullPath);
          this.$router.push('/404').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
        return data.teams_items;
      },
      skip() {
        return !this.groupId
          || this.currentItemObject === null
          || !this.userObject
          || this.userObject.isGroupUser;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    checkForRootFolder(teamsItems) {
      teamsItems.some((teamsItem) => {
        if (teamsItem.folder !== null && teamsItem.folder.root === true) {
          this.accessToRootGroup = true;
          return true;
        }
        return false;
      });
    },
    checkForExistingTeamItemsInCurrentItemPath(teamsItems) {
      let teamItemIsInCurrentItemPath = false;
      teamsItems.some((teamsItem) => {
        console.log('teamsItemITEMWRAPPER', teamsItem);
        if (teamsItem.folder !== null) {
          const folderIdForPath = `f${teamsItem.folder.folder_id}`;
          console.log('(teamsItem.folder', teamsItem.folder);
          console.log('folderIdForPath', folderIdForPath);
          console.log('this.$route.fullPath', this.$route.fullPath);
          console.log('this.$route.path', this.$route.path);
          console.log('this.currentItemObject', this.currentItemObject);
          // check on the root path (team dashboard)
          if (this.$route.path === '/') {
            teamItemIsInCurrentItemPath = true;
            return true;
          }
          if (this.currentItemObject
            && this.currentItemObject.itemPath
            && this.currentItemObject.itemPath.search(folderIdForPath) !== -1) {
            teamItemIsInCurrentItemPath = true;
            return true;
          }
        }
        if (teamsItem.project !== null) {
          const projectIdForPath = `pr${teamsItem.project.project_id}`;
          console.log('(teamsItem.project', teamsItem.project);
          console.log('projectIdForPath', projectIdForPath);
          console.log('this.$route.fullPath', this.$route.fullPath);
          console.log('this.$route.path', this.$route.path);
          console.log('this.currentItemObject', this.currentItemObject);
          if (this.currentItemObject
            && this.currentItemObject.itemPath
            && this.currentItemObject.itemPath.search(projectIdForPath) !== -1) {
            teamItemIsInCurrentItemPath = true;
            return true;
          }
        }
        return false;
      });
      console.log('teamItemIsInCurrentItemPath', teamItemIsInCurrentItemPath);
      return teamItemIsInCurrentItemPath;
    },
    setTypeOfPageInApolloCache(type) {
      this.$apollo.mutate({
        mutation: SetPageTypeInCache,
        variables: {
          type,
        },
      });
      this.$apollo.mutate({
        mutation: SetRunBreadcrumbQueryInCache,
        variables: {
          runQuery: true,
        },
      });
      this.$apollo.mutate({
        mutation: SetShowHeaderInCache,
        variables: {
          show: true,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-size: 1.1rem;
}

.items-wrapper {
  min-height: calc(100vh - 78px);

  &.page {
    background-color: #fff;
  }
}
</style>
