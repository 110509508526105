import Vue from 'vue';

const cloudImagePrefixUrl = 'https://axyqwmwryo.cloudimg.io/v7/';

const cloudimage = {
  // eslint-disable-next-line no-unused-vars,no-shadow
  install(Vue, options) {
    /**
     * The url is always required!
     *
     * containerDimensions have to have: width, height
     * imageDimensions have to have: width, height
     *
     * the config is used to add parameters to the cloudImage url
     */
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$makeCloudImage = (
      url,
      containerDimensions = null,
      imageDimensions = null,
      config = null,
      forceUserDimensions = false,
    ) => {
      let targetWidth = 0;
      // let targetHeight = 0;

      if (!url) return '';

      // test for absolute url
      const r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (!r.test(url)) return url;

      if (
        imageDimensions?.width
        && imageDimensions?.height
        && containerDimensions?.width
        && containerDimensions?.height
      ) {
        // const imgRatio = imageDimensions.width / imageDimensions.height;
        // const containerRatio = containerDimensions.width / containerDimensions.height;
        // if (imgRatio >= 1 && containerRatio >= 1) {
        //   // set the height and not the width!
        //   targetHeight = containerDimensions.height;
        //   if (targetHeight < 600 && !forceUserDimensions) targetHeight = 600;
        // } else {
        // set the width and not the height!
        targetWidth = containerDimensions.width;
        // if (targetWidth < 600 && !forceUserDimensions) targetWidth = 600;
        if (targetWidth < 600) targetWidth = 600;
        // }
        if (!forceUserDimensions) {
          // targetHeight = Math.ceil(targetHeight / 100) * 100;
          targetWidth = Math.ceil(targetWidth / 100) * 100;
        }
      }

      const width = (targetWidth !== 0) ? `&w=${targetWidth}` : '';
      // const height = (targetHeight !== 0) ? `&h=${targetHeight}` : '';
      const quality = (config?.quality) ? `&q=${config.quality}` : '&q=95';
      return `${cloudImagePrefixUrl}${encodeURI(url)}?ci_url_encoded=1&org_if_sml=1&alt=media&func=bound${width}${quality}`;
    };
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$checkForCloudImage = (url) => !!url.startsWith(cloudImagePrefixUrl);
  },
};

Vue.use(cloudimage);
