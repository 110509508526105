var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    { attrs: { show: _vm.$apollo.loading, rounded: "sm", variant: "dark" } },
    [
      _vm.userObject && _vm.disableAccount === true
        ? _c("modal-suspended-account", {
            attrs: {
              "group-id": _vm.groupId,
              "user-id": _vm.userObject.userId,
              "is-sys-admin": _vm.userObject.isSysAdmin
            }
          })
        : _vm.currentItemObject &&
          _vm.currentItemObject.incompatibleItem &&
          _vm.userObject &&
          !_vm.userObject.isSysAdmin
        ? _c("modal-incompatible-item", {
            attrs: {
              "item-name": _vm.incompatibleItemName,
              item: _vm.currentItemObject,
              "modal-for-detail-page": true
            }
          })
        : !_vm.userObject.isSysAdmin &&
          _vm.userObject.isGroupUser === false &&
          _vm.$route.fullPath === "/" &&
          _vm.allItemsIdsOfTeam &&
          _vm.accessToRootGroup === false
        ? _c("team-dashboard", {
            attrs: {
              "team-ids": _vm.userObject.teamIdsOfUser,
              "user-id": _vm.userObject.userId,
              "group-id": _vm.groupId,
              "teams-items": _vm.allItemsIdsOfTeam
            },
            on: {
              "refresh-items-query": function($event) {
                return _vm.$apollo.queries.allItemsIdsOfTeam.refetch()
              }
            }
          })
        : _vm.isGroupUser ||
          _vm.$route.fullPath !== "/" ||
          _vm.accessToRootGroup === true ||
          _vm.userObject.isSysAdmin
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "items-wrapper",
                class: _vm.currentItemObject ? _vm.currentItemObject.type : ""
              },
              [
                _vm.currentItemObject &&
                _vm.currentItemObject.type === "page" &&
                _vm.groupObject &&
                !_vm.currentItemObject.incompatible_item
                  ? _c("page-item", {
                      attrs: {
                        "user-id": _vm.userObject.userId,
                        "page-id": _vm.currentItemObject.id,
                        "item-path": _vm.currentItemObject.itemPath,
                        privileges: _vm.currentItemObject.privilegesForItem,
                        "item-name": _vm.currentItemObject.name,
                        "is-sys-admin": _vm.userObject.isSysAdmin,
                        language: _vm.userObject.language,
                        group: _vm.groupObject
                      }
                    })
                  : _vm.currentItemObject
                  ? _c("dashboard", {
                      attrs: {
                        "current-item": _vm.currentItemObject,
                        "user-object": _vm.userObject,
                        "all-available-privileges": _vm.allAvailablePrivileges,
                        "group-id": _vm.groupId
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }