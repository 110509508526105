import Vue from 'vue';

const PRIVILEGES = {
  CAN_CREATE_FOLDERS: 'can_create_folders',
  CAN_CREATE_PAGES: 'can_create_pages',
  CAN_CREATE_PROJECTS: 'can_create_projects',
  CAN_DELETE_FOLDERS: 'can_delete_folders',
  CAN_DELETE_PAGES: 'can_delete_pages',
  CAN_DELETE_PROJECTS: 'can_delete_projects',
  CAN_DUPLICATE_ITEMS: 'can_duplicate_items',
  CAN_EDIT_CUSTOM_CSS: 'can_edit_custom_css',
  CAN_EDIT_PAGE: 'can_edit_page',
  CAN_EDIT_PAGE_LAYOUTS: 'can_edit_page_layouts',
  CAN_EDIT_PAGE_SETTINGS: 'can_edit_page_settings',
  CAN_EDIT_PAGE_SETTINGS_PAGE: 'can_edit_page_settings_page',
  CAN_EDIT_PAGE_SETTINGS_SEO: 'can_edit_page_settings_seo',
  CAN_EDIT_PAGE_SNIPPETS: 'can_edit_page_snippets',
  CAN_EDIT_PAGE_SNIPPET_SETTINGS: 'can_edit_page_snippet_settings',
  CAN_EDIT_PAYMENT: 'can_edit_payment',
  CAN_EDIT_FOLDER_SETTINGS: 'can_edit_folder_settings',
  CAN_EDIT_PROJECT_SETTINGS: 'can_edit_project_settings',
  CAN_EDIT_ROOT_SETTINGS: 'can_edit_root_settings',
  CAN_EDIT_SETTINGS_CI: 'can_edit_settings_ci',
  CAN_EDIT_SETTINGS_EMBED: 'can_edit_settings_embed',
  CAN_EDIT_SETTINGS_NAVIGATION: 'can_edit_settings_navigation',
  CAN_EDIT_SETTINGS_PLUGINS: 'can_edit_settings_plugins',
  CAN_EDIT_SETTINGS_PUBLICATION: 'can_edit_settings_publication',
  CAN_EDIT_TEAMS: 'can_edit_teams',
  CAN_EDIT_GROUPS: 'can_edit_groups',
  CAN_ADD_ADMINS_TO_GROUP: 'can_add_admins_to_group',
  CAN_EDIT_ADMINS_OF_GROUP: 'can_edit_admins_of_group',
  CAN_REMOVE_ADMINS_OF_GROUP: 'can_remove_admins_of_group',
  CAN_ADD_SUBGROUP_TO_GROUP: 'can_add_subgroup_to_group',
  CAN_REMOVE_SUBGROUP_OF_GROUP: 'can_remove_subgroup_of_group',
  CAN_SEE_ACCOUNT_MANAGERS: 'can_see_account_managers',
  CAN_EDIT_USERS: 'can_edit_users',
  CAN_HAVE_N_FOLDERS: 'can_have_n_folders',
  CAN_HAVE_N_GB_STORAGE: 'can_have_n_gb_storage',
  CAN_HAVE_N_PAGES: 'can_have_n_pages',
  CAN_HAVE_N_PROJECTS: 'can_have_n_projects',
  CAN_HAVE_N_TEAMS: 'can_have_n_teams',
  CAN_HAVE_N_USERS: 'can_have_n_users',
  CAN_HAVE_SUB_GROUPS: 'can_have_sub_groups',
  CAN_HAVE_TEAMS: 'can_have_teams',
  CAN_MOVE_ITEMS: 'can_move_items',
  CAN_PUBLISH_ITEMS: 'can_publish_items',
  CAN_SHARE_ITEMS: 'can_share_items',
  CAN_SORT_ITEMS: 'can_sort_items',
  CAN_UNPUBLISH_ITEMS: 'can_unpublish_items',
  CAN_USE_123_CHAT: 'can_use_123_chat',
  CAN_EDIT_123_CHAT: 'can_edit_123_chat',
  CAN_USE_COOKIEBOT: 'can_use_cookiebot',
  CAN_EDIT_COOKIEBOT: 'can_edit_cookiebot',
  CAN_USE_CCM19: 'can_use_ccm19',
  CAN_EDIT_CCM19: 'can_edit_ccm19',
  CAN_USE_COOKIES_CONSENT: 'can_use_cookies_consent',
  CAN_EDIT_COOKIES_CONSENT: 'can_edit_cookies_consent',
  CAN_USE_DISQUS: 'can_use_disqus',
  CAN_EDIT_DISQUS: 'can_edit_disqus',
  CAN_USE_EXIT_INTENT: 'can_use_exit_intent',
  CAN_EDIT_EXIT_INTENT: 'can_edit_exit_intent',
  CAN_USE_USERCENTRICS: 'can_use_usercentrics',
  CAN_EDIT_USERCENTRICS: 'can_edit_usercentrics',
  CAN_USE_PLAUSIBLE: 'can_use_plausible',
  CAN_EDIT_PLAUSIBLE: 'can_edit_plausible',
  CAN_USE_FACEBOOK_PIXEL: 'can_use_facebook_pixel',
  CAN_EDIT_FACEBOOK_PIXEL: 'can_edit_facebook_pixel',
  CAN_USE_FLOATING_ICON_MENU: 'can_use_floating_icon_menu',
  CAN_EDIT_FLOATING_ICON_MENU: 'can_edit_floating_icon_menu',
  CAN_USE_GATED_CONTENT_HUBSPOT: 'can_use_gated_content_hubspot',
  CAN_EDIT_GATED_CONTENT_HUBSPOT: 'can_edit_gated_content_hubspot',
  CAN_USE_GOOGLE_ANALYTICS: 'can_use_google_analytics',
  CAN_EDIT_GOOGLE_ANALYTICS: 'can_edit_google_analytics',
  CAN_USE_GOOGLE_TAG_MANAGER: 'can_use_google_tag_manager',
  CAN_EDIT_GOOGLE_TAG_MANAGER: 'can_edit_google_tag_manager',
  CAN_USE_LINKEDIN_PIXEL: 'can_use_linkedin_pixel',
  CAN_EDIT_LINKEDIN_PIXEL: 'can_edit_linkedin_pixel',
  CAN_USE_FEEDBUCKET: 'can_use_feedbucket',
  CAN_EDIT_FEEDBUCKET: 'can_edit_feedbucket',
  CAN_USE_MATOMO_ANALYTICS: 'can_use_matomo_analytics',
  CAN_EDIT_MATOMO_ANALYTICS: 'can_edit_matomo_analytics',
  CAN_USE_PASSWORD_PROTECTION: 'can_use_password_protection',
  CAN_EDIT_PASSWORD_PROTECTION: 'can_edit_password_protection',
  CAN_USE_WEBMAG_ANALYTICS: 'can_use_webmag_analytics',
  CAN_EDIT_WEBMAG_ANALYTICS: 'can_edit_webmag_analytics',
  CAN_USE_WEBMAG_ANALYTICS_COOKIES: 'can_use_webmag_analytics_cookies',
  CAN_EDIT_WEBMAG_ANALYTICS_COOKIES: 'can_edit_webmag_analytics_cookies',
  CAN_EDIT_WEGLOT: 'can_edit_weglot',
  CAN_EDIT_VARIABLES_PLUGIN: 'can_edit_variables_plugin',
  CAN_USE_VARIABLES_PLUGIN: 'can_use_variables_plugin',
  CAN_USE_INTRO_PLUGIN: 'can_use_intro_plugin',
  CAN_EDIT_INTRO_PLUGIN: 'can_edit_intro_plugin',
  CAN_USE_CHATGPT: 'can_use_chatgpt',
  CAN_EDIT_CHATGPT: 'can_edit_chatgpt',
  CAN_CREATE_PDF: 'can_create_pdf',
  CAN_USE_LEAD_MODAL: 'can_use_lead_modal',
  CAN_EDIT_LEAD_MODAL: 'can_edit_lead_modal',
};

const constants = {
  // eslint-disable-next-line no-unused-vars,no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$privileges = (key) => PRIVILEGES[key];
  },
};

Vue.use(constants);
