import GetIdBySlugPath from '@/graphQlQueries/queries/getIdBySlugPath';

// to use this mixin we also have to insert the userDataAndAccess mixin!!
const getItemFromUrl = {
  data() {
    return {
      itemTypes: [
        'folder',
        'project',
        'page',
      ],
      currentItemObject: null,
    };
  },
  apollo: {
    currentItemObject: {
      query: GetIdBySlugPath,
      variables() {
        const path = (this.$route.params.parametersOfUrl)
          ? `/${this.$route.params.parametersOfUrl}`
          : '/';
        return {
          groupId: this.groupId,
          path,
        };
      },
      update(data) {
        if (data.item_lookup.length === 0) {
          if (this.$route.path !== '/') {
            this.$router.push('/404');
          }
          return false;
        }
        return this.getThePreparedCurrentItem(data.item_lookup[0]);
      },
      skip() {
        return !this.groupId || this.userObject.userId === null;
      },
      error(errorMsg) {
        const debugData = `${this.userEmail}, ${this.userId}, ${this.slugPath}`;
        if (errorMsg.message.includes('Your requested role is not in allowed roles')) {
          this.$router.push({
            path: '/not-in-allowed-roles',
            query: {
              error: 'accountSetupProblem',
              debugData,
              errorData: JSON.stringify(errorMsg),
            },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        } else {
          this.$router.push({
            path: '/oops-something-went-wrong',
            query: {
              error: 'accountSetupProblem',
              debugData,
              errorData: JSON.stringify(errorMsg),
            },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    getThePreparedCurrentItem(item) {
      let currentItemType = null;
      this.itemTypes.some((type) => {
        if (item[`${type}_id`] !== null) {
          currentItemType = type;
          return true;
        }
        return false;
      });
      const itemPath = item.item_path;
      const { userGroupRoleIds } = this.userObject;
      const itemTeamRoles = this.getTheTeamRolesForItem(itemPath);
      const itemAllRoleIds = [...new Set([
        ...userGroupRoleIds,
        ...itemTeamRoles,
      ])];
      const privilegesForItem = this.getAllPrivilegesByRoleIds(itemAllRoleIds);
      // distinguish between lookup_item and fetch_item!
      const name = (item.name) ? item.name : item[currentItemType].name;
      let incompatibleItem = false;
      let parentProjectName = null;
      if (item.project) {
        incompatibleItem = item.project.incompatible_item;
      }
      if (item.page && item.page.project) {
        incompatibleItem = item.page.parent_project.incompatible_item;
        parentProjectName = item.page.parent_project.name;
      }
      return {
        id: item[`${currentItemType}_id`],
        type: currentItemType,
        incompatibleItem,
        name,
        itemPath,
        itemAllRoleIds,
        privilegesForItem,
        parentProjectName,
      };
    },
    getTheTeamRolesForItem(itemPath) {
      const teamRoles = [];
      this.userObject.teamItemsOfUser.forEach((teamObject) => {
        teamObject.team.teams_items.some((teamItem) => {
          let teamItemItemPath = null;
          if (teamItem.folder) {
            teamItemItemPath = teamItem.folder.item_path;
          }
          if (teamItem.project) {
            teamItemItemPath = teamItem.project.item_path;
          }
          if (teamItem.page) {
            teamItemItemPath = teamItem.page.item_path;
          }
          if (itemPath.search(teamItemItemPath) !== -1 && !teamRoles.includes(teamObject.role_id)) {
            teamRoles.push(teamObject.role_id);
            return true;
          }
          return false;
        });
      });
      return teamRoles;
    },
    getAllPrivilegesByRoleIds(userRoles) {
      let allCurrentPrivilegesForGroup = [];
      userRoles.forEach((roleId) => {
        // get the entry with the roleId
        const {
          roles_privileges: privilegesOfRole,
        } = this.allAvailablePrivileges.find((priv) => priv.role_id === roleId);
        const allPrivilegesOfRolesByName = privilegesOfRole.map((item) => item.privilege.name);
        allCurrentPrivilegesForGroup = [...new Set([
          ...allCurrentPrivilegesForGroup,
          ...allPrivilegesOfRolesByName,
        ])];
      });
      return allCurrentPrivilegesForGroup;
    },
  },
};

export default getItemFromUrl;
