import Vue from 'vue';
import { apolloProvider } from '@/plugins/apollo';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import './plugins/bootstrap';
import '@/plugins/awsAuth';
import '@/plugins/cloudimage';
import '@/plugins/constants';
import '@/plugins/froala';
import '@/plugins/privileges';
import '@/plugins/userRole';
import '@/plugins/vueShepherd';
import '@/plugins/localStorage';
import Auth from '@aws-amplify/auth';
import { localStorage } from '@/plugins/localStorage';

Vue.config.productionTip = false;

let app;
if (Auth && !app) {
  app = new Vue({
    apolloProvider,
    i18n,
    router,
    localStorage,
    render: (h) => h(App),
  }).$mount('#app');
}

