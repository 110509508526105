import gql from 'graphql-tag';

export default gql`
{
  buttonDisabled @client {
    id
    isButtonDisabled
  }
}
`;
