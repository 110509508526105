import gql from 'graphql-tag';

export default gql`
{
  lastVisitedUrl @client {
    id
    url
  }
}
`;
