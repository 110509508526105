<template>
  <b-overlay :show="$apollo.loading || loadingIndicator" rounded="sm" variant="dark">
    <useCrisp
      v-if="useCrisp && !isMaintenance"
      :email="userEmail"
      :group-name="groupName"
    />
    <div id="app" :class="pageType">
      <maintenance-mode v-if="isMaintenance"/>
      <div v-else-if="loading" id="content-wrapper">
        <content-header
          v-if="showHeader"
          :key="`header-${$route.fullPath}`"
        />
        <div id="content">
          <router-view :key="$route.fullPath" @useCrisp="useCrispScript" />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import SetLastVisitedUrlInCache from '@/graphQlQueries/mutations/setLastVisitedUrlInCache';
import GetLoadingIndicatorFromCache from '@/graphQlQueries/queries/getLoadingIndicatorFromCache';
import GetLastGroupId from '@/graphQlQueries/queries/getLastGroupId';
import GetGroupDataByGroupId from '@/graphQlQueries/queries/getGroupDataByGroupId';
import GetPageTypeFromCache from '@/graphQlQueries/queries/getPageTypeFromCache';
import ShowHeaderFromCache from '@/graphQlQueries/queries/getShowHeaderFromCache';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';
import Auth from '@aws-amplify/auth';
// editor v1
import $ from 'jquery';

import { createPopper } from '@popperjs/core';

global.Popper = createPopper;
global.$ = $;
global.jQuery = $;

export default {
  name: 'App',
  components: {
    ContentHeader: () => import('@/components/header/Header.vue'),
    MaintenanceMode: () => import('@/components/helper/MaintenanceMode.vue'),
    UseCrisp: () => import('@/components/useCrisp.vue'),
  },
  data() {
    return {
      useGist: false,
      useCrisp: false,
      userEmail: null,
      groupName: null,
      pageType: null,
      loading: false,
      isSysAdmin: false,
      loadingIndicator: false,
      isMaintenance: (process.env.VUE_APP_MAINTENANCE === 'true'),
      blacklistForHeader: [
        '/webmag-login',
        '/webmag-signup',
        '/webmag-verify',
        '/webmag-setup',
        '/webmag-logout',
        '/webmag-password-reset',
        '/oops-something-went-wrong',
        '/not-in-allowed-roles',
        '/404',
      ],
    };
  },
  created() {
    this.loading = true;
  },
  apollo: {
    pageType: {
      query: GetPageTypeFromCache,
      update(data) {
        return (data.pageType)
          ? data.pageType.type
          : null;
      },
    },
    loadingIndicator: {
      query: GetLoadingIndicatorFromCache,
      update(data) {
        return data.loadingIndicator.isIndicatorLoading;
      },
    },
    showHeaderQuery: {
      query: ShowHeaderFromCache,
      update(data) {
        return (data.showHeader)
          ? data.showHeader.show
          : false;
      },
    },
  },
  computed: {
    showHeader() {
      return !this.blacklistForHeader.includes(this.$route.path) && this.showHeaderQuery;
    },
  },
  methods: {
    useCrispScript() {
      this.useCrisp = true;
    },
    addGistToApplication() {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((user) => {
        if (user && user.attributes && !this.isMaintenance) {
          this.userEmail = user.attributes.email;
          // check if user is sys_admin
          this.$apollo.query({
            query: GetUserByEmail,
            variables: {
              email: user.attributes.email,
            },
            fetchPolicy: 'network-only',
          }).then((userQueryObject) => {
            if (userQueryObject.data.users.length) {
              const { data: { users: [userObject] } } = userQueryObject;
              this.isSysAdmin = userObject.sys_admin;
            }
            this.$apollo.query({
              query: GetLastGroupId,
              variables: {
                email: user.attributes.email,
              },
            }).then((groupIdUserData) => {
              if (groupIdUserData.data.users.length) {
                const { data: { users: [groupUserObject] } } = groupIdUserData;
                let groupId = null;
                if (groupUserObject.last_group_id !== null) {
                  groupId = groupUserObject.last_group_id;
                } else {
                  groupId = groupUserObject.home_group_id;
                }
                if (groupId !== null) {
                  this.$apollo.query({
                    query: GetGroupDataByGroupId,
                    variables: {
                      groupId,
                    },
                  }).then((groupObject) => {
                    if (groupObject.data.groups_by_pk) {
                      // here we have to check the group permissions to see if gist is allowed
                      // if we don't reach this point gist is allowed
                      const disableGistPriv = this.findGroupPrivilegeValue('disable_gist', groupObject.data);
                      if (disableGistPriv !== true) {
                        const { data: { groups_by_pk: { name } } } = groupObject;
                        this.groupName = name;
                        this.useGist = true;
                        this.useCrisp = true;
                      } else {
                        this.useGist = false;
                        this.useCrisp = false;
                      }
                    } else {
                      // there is no group for the user
                      this.useGist = true;
                      this.useCrisp = true;
                    }
                  });
                } else {
                  // if there is no last group id or home group id we don't use the group id
                  this.useGist = true;
                  this.useCrisp = true;
                }
              } else {
                // if there is no user! we should never be here
                this.useGist = true;
                this.useCrisp = true;
              }
            });
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    findGroupPrivilegeValue(privilegeName, data) {
      if (data.groups_by_pk) {
        const privObject = data.groups_by_pk.groups_privileges.find(
          (groupPriv) => groupPriv.privilege.handle === privilegeName,
        );
        if (privObject) {
          return privObject.value;
        }
      }
      // if no result was found use the default data
      const allGroupPrivileges = data.privileges;
      const groupPrivilege = allGroupPrivileges.find(
        (groupPriv) => groupPriv.handle === privilegeName,
      );
      if (groupPrivilege) {
        return groupPrivilege.default_value;
      }
      return null;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async $route(to, from) {
      this.addGistToApplication();
      if (to.hash === '') {
        await this.$apollo.mutate({
          mutation: SetLastVisitedUrlInCache,
          variables: {
            url: from.path,
          },
        });
      }
    },
  },
};

</script>

<style lang="scss">
@import "./scss/global";

div.vue-tour {
  border-radius: 0;
  text-align: center;

  .shepherd-title {
    justify-content: center;
  }

  .shepherd-text {
    padding: 30px;
  }

  .shepherd-footer {
    justify-content: center;
    padding-bottom: 30px;

    button {
      border-radius: 0;
      text-transform: uppercase;
      font-size: .8em;

      &.prev {
        color: #44484c;
        background-color: #e0e2e4;
        border-color: #e0e2e4;
      }
    }
  }
}

.b-overlay {
  z-index: 2000 !important;
  position: fixed !important;
}

.filepond--root .filepond--drop-label,
.filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
}

//.header {
//  height: 65px;
//  border-bottom: 1px solid #36393d;
//}
</style>
