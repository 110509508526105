import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import VueApollo from 'vue-apollo';
import Auth from '@aws-amplify/auth';
import gql from 'graphql-tag';
import GetAutosaveIndicatorForHeaderFromCache from '@/graphQlQueries/queries/getAutosaveIndicatorForHeaderFromCache';
import GetLoadingIndicatorFromCache from '@/graphQlQueries/queries/getLoadingIndicatorFromCache';
import GetPreviewWindowFromCache from '@/graphQlQueries/queries/getPreviewWindowFromCache';
import GetItemUrlWindowFromCache from '@/graphQlQueries/queries/getItemUrlWindowFromCache';
import GetPageNavigationLinksFromCache from '@/graphQlQueries/queries/getPageNavigationLinksFromCache';
import GetButtonDisabledFromCache from '@/graphQlQueries/queries/getButtonDisabledFromCache';
import GetButtonsDisabledOnAutosaveFromCache from '@/graphQlQueries/queries/getButtonsDisabledOnAutosaveFromCache';
import GetRunBreadcrumbQueryFromCache from '@/graphQlQueries/queries/getRunBreadcrumbQueryFromCache';
import GetLastVisitedUrlFromCache from '@/graphQlQueries/queries/getLastVisitedUrlFromCache';
import GetShowHeaderFromCache from '@/graphQlQueries/queries/getShowHeaderFromCache';
import GetPageTypeFromCache from '@/graphQlQueries/queries/getPageTypeFromCache';
import GetPageHasToBePublished from '@/graphQlQueries/queries/getPageHasToBePublishedFromCache';
import GetProjectHasToBePublished from '@/graphQlQueries/queries/getProjectHasToBePublishedFromCache';
import GetDataHasChangedForSaving from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';

// config
const authLink = setContext(async (_, { headers, ...context }) => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken();
  const token = idToken.getJwtToken();
  // console.log('token', token);
  console.log('HASURA-USER-ROLE', Vue.prototype.$currentUserRole);
  const hasuraRole = { 'x-hasura-role': Vue.prototype.$currentUserRole };
  // const hasuraRole = { 'x-hasura-role': 'user' };
  return {
    headers: {
      ...headers,
      ...hasuraRole,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
    ...context,
  };
});
// Put value on window to make available to JQuery within PHP.
const { VUE_APP_GRAPHQL_ENDPOINT } = process.env;
window.VUE_APP_GRAPHQL_ENDPOINT = `${VUE_APP_GRAPHQL_ENDPOINT}/v1/graphql`;
const httpLink = new HttpLink({
  uri: `${VUE_APP_GRAPHQL_ENDPOINT}/v1/graphql`,
});

const typeDefs = gql`
  type UserId {
    id: ID!
  }
  type PageType {
    id: ID!,
    type: String
  }
  type RunBreadcrumbQuery {
    id: ID!,
    runQuery: Boolean!
  }
  type ShowHeader {
    id: ID!,
    show: Boolean!
  }
  type LastVisitedUrl {
    id: ID!,
    url: String!
  }
  type PageHasToBePublished {
    id: ID!,
    isPublished: Boolean!,
    hasChanged: Boolean!,
    publishButtonIsPressed: Boolean!,
    hasParentProject: Boolean!,
    parentProjectIsPublished: Boolean!
  }
  type ProjectHasToBePublished {
    id: ID!,
    isPublished: Boolean!,
    hasChanged: Boolean!,
    publishButtonIsPressed: Boolean!,
  }
  type DataHasChangedForSaving {
    id: ID!,
    pageType: String,
    isDataChanged: Boolean!,
    isSubmittedButtonPressed: Boolean!,
    isCancelButtonPressed: Boolean!,
    isDataBePublished: Boolean!
  }
  type PreviewWindow {
    id: ID!,
    isPreviewWindowOpen: Boolean!
  }
  type ItemUrlWindow {
    id: ID!,
    openItemUrlWindow: Boolean!
    itemPath: String
  }
  type PageNavigationLinks {
    id: ID!,
    prevLink: String
    nextLink: String
  }
  type AutosaveIndicatorForHeader {
    id: ID!,
    isSavingData: Boolean!,
    allSaved: Boolean!
  }
  type LoadingIndicatorForOverlay {
    id: ID!,
    isIndicatorLoading: Boolean!
  }
  type ButtonDisabled {
    id: ID!,
    isButtonDisabled: Boolean!
  }
  type ButtonsDisabledOnAutosave {
    id: ID!,
    isButtonsDisabledOnAutosave: Boolean!
  }
  type Mutation {
    setPageType(type: String!): PageType,
    setRunBreadcrumbQuery(runQuery: Boolean!): RunBreadcrumbQuery,
    setShowHeader(show: Boolean!): ShowHeader,
    setLastVisitedUrl(url: String!): LastVisitedUrl,
    setPageHasToBePublished(
      isPublished: Boolean!,
      hasChanged: Boolean!,
      publishButtonIsPressed: Boolean!,
      hasParentProject: Boolean!,
      parentProjectIsPublished: Boolean!
      ): PageHasToBePublished,
    setProjectHasToBePublished(
      isPublished: Boolean!,
      hasChanged: Boolean!,
      publishButtonIsPressed: Boolean!,
      ): ProjectHasToBePublished,
    setDataHasChangedForSaving(
      pageType: String,
      isDataChanged: Boolean!,
      isSubmittedButtonPressed: Boolean!,
      isCancelButtonPressed: Boolean!,
      isDataPublished: Boolean!
      ): DataHasChangedForSaving,
    setPreviewWindow(isPreviewWindowOpen: Boolean!): PreviewWindow,
    setItemUrlWindow(
      openItemUrlWindow: Boolean!
      itemPath: String
    ): ItemUrlwWindow,
    setPageNavigationLinks(prevLink: String, nextLink: String): PageNavigationLinks,
    setAutosaveIndicatorForHeader(
      isSavingData: Boolean!,
      allSaved: Boolean!
    ): AutosaveIndicatorForHeader,
    setLoadingIndicator(isIndicatorLoading: Boolean!): LoadingIndicatorForOverlay,
    setButtonDisabled(isButtonDisabled: Boolean!): ButtonDisabled
    setButtonsDisabledOnAutosave(isButtonsDisabledOnAutosave: Boolean!): ButtonsDisabledOnAutosave
  }
`;

const cache = new InMemoryCache();

const resolvers = {
  Mutation: {
    // eslint-disable-next-line no-shadow
    setPageType: (_, { type }, { cache }) => {
      const data = cache.readQuery({ query: GetPageTypeFromCache });
      data.pageType.type = type;
      cache.writeQuery({ query: GetPageTypeFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setRunBreadcrumbQuery: (_, { runQuery }, { cache }) => {
      const data = cache.readQuery({ query: GetRunBreadcrumbQueryFromCache });
      data.runBreadcrumbQuery.runQuery = runQuery;
      cache.writeQuery({ query: GetRunBreadcrumbQueryFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setShowHeader: (_, { show }, { cache }) => {
      const data = cache.readQuery({ query: GetShowHeaderFromCache });
      data.showHeader.show = show;
      cache.writeQuery({ query: GetShowHeaderFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setLastVisitedUrl: (_, { url }, { cache }) => {
      const data = cache.readQuery({ query: GetLastVisitedUrlFromCache });
      data.lastVisitedUrl.url = url;
      cache.writeQuery({ query: GetLastVisitedUrlFromCache, data });
      return data;
    },
    setPageHasToBePublished: (
      _,
      {
        isPublished,
        hasChanged,
        publishButtonIsPressed,
        hasParentProject,
        parentProjectIsPublished,
      },
      // eslint-disable-next-line no-shadow
      { cache },
    ) => {
      const data = cache.readQuery({ query: GetPageHasToBePublished });
      data.pageHasToBePublished.isPublished = isPublished;
      data.pageHasToBePublished.hasChanged = hasChanged;
      data.pageHasToBePublished.publishButtonIsPressed = publishButtonIsPressed;
      data.pageHasToBePublished.hasParentProject = hasParentProject;
      data.pageHasToBePublished.parentProjectIsPublished = parentProjectIsPublished;
      cache.writeQuery({ query: GetPageHasToBePublished, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setProjectHasToBePublished: (
      _,
      {
        isPublished,
        hasChanged,
        publishButtonIsPressed,
      },
      // eslint-disable-next-line no-shadow
      { cache },
    ) => {
      const data = cache.readQuery({ query: GetProjectHasToBePublished });
      data.projectHasToBePublished.isPublished = isPublished;
      data.projectHasToBePublished.hasChanged = hasChanged;
      data.projectHasToBePublished.publishButtonIsPressed = publishButtonIsPressed;
      cache.writeQuery({ query: GetProjectHasToBePublished, data });
      return data;
    },
    setDataHasChangedForSaving: (
      _,
      {
        pageType,
        isDataChanged,
        isSubmittedButtonPressed,
        isCancelButtonPressed,
        isDataPublished,
      },
      // eslint-disable-next-line no-shadow
      { cache },
    ) => {
      const data = cache.readQuery({ query: GetDataHasChangedForSaving });
      data.dataHasChangedForSaving.pageType = pageType;
      data.dataHasChangedForSaving.isDataChanged = isDataChanged;
      data.dataHasChangedForSaving.isSubmittedButtonPressed = isSubmittedButtonPressed;
      data.dataHasChangedForSaving.isCancelButtonPressed = isCancelButtonPressed;
      data.dataHasChangedForSaving.isDataPublished = isDataPublished;
      cache.writeQuery({ query: GetDataHasChangedForSaving, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setPreviewWindow: (_, { isPreviewWindowOpen }, { cache }) => {
      const data = cache.readQuery({ query: GetPreviewWindowFromCache });
      data.previewwWindow.isPreviewWindowOpen = isPreviewWindowOpen;
      cache.writeQuery({ query: GetPreviewWindowFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setItemUrlWindow: (_, { openItemUrlWindow, itemPath }, { cache }) => {
      const data = cache.readQuery({ query: GetItemUrlWindowFromCache });
      data.itemUrlWindow.openItemUrlWindow = openItemUrlWindow;
      data.itemUrlWindow.itemPath = itemPath;
      cache.writeQuery({ query: GetItemUrlWindowFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setPageNavigationLinks: (_, { prevLink, nextLink }, { cache }) => {
      const data = cache.readQuery({ query: GetPageNavigationLinksFromCache });
      data.pageNavigationLinks.prevLink = prevLink;
      data.pageNavigationLinks.nextLink = nextLink;
      cache.writeQuery({ query: GetPageNavigationLinksFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setAutosaveIndicatorForHeader: (_, { isSavingData, allSaved }, { cache }) => {
      const data = cache.readQuery({ query: GetAutosaveIndicatorForHeaderFromCache });
      data.autosaveIndicator.isSavingData = isSavingData;
      data.autosaveIndicator.allSaved = allSaved;
      cache.writeQuery({ query: GetAutosaveIndicatorForHeaderFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setLoadingIndicator: (_, { isIndicatorLoading }, { cache }) => {
      const data = cache.readQuery({ query: GetLoadingIndicatorFromCache });
      data.loadingIndicator.isIndicatorLoading = isIndicatorLoading;
      cache.writeQuery({ query: GetLoadingIndicatorFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setButtonDisabled: (_, { isButtonDisabled }, { cache }) => {
      const data = cache.readQuery({ query: GetButtonDisabledFromCache });
      data.buttonDisabled.isButtonDisabled = isButtonDisabled;
      cache.writeQuery({ query: GetButtonDisabledFromCache, data });
      return data;
    },
    // eslint-disable-next-line no-shadow
    setButtonsDisabledOnAutosave: (_, { isButtonsDisabledOnAutosave }, { cache }) => {
      const data = cache.readQuery({ query: GetButtonsDisabledOnAutosaveFromCache });
      data.buttonsDisabledOnAutosave.isButtonsDisabledOnAutosave = isButtonsDisabledOnAutosave;
      cache.writeQuery({ query: GetButtonsDisabledOnAutosaveFromCache, data });
      return data;
    },
  },
};

// Create the apollo client
export const apolloClient = new ApolloClient({
  cache,
  typeDefs,
  resolvers,
  link: authLink.concat(httpLink),
  connectToDevTools: true,
});

cache.writeData({
  data: {
    userId: {
      __typename: 'UserId',
      id: 1,
    },
    pageType: {
      __typename: 'PageType',
      id: 1,
      type: null,
    },
    runBreadcrumbQuery: {
      __typename: 'RunBreadcrumbQuery',
      id: 1,
      runQuery: false,
    },
    showHeader: {
      __typename: 'ShowHeader',
      id: 1,
      show: false,
    },
    lastVisitedUrl: {
      __typename: 'LastVisitedUrl',
      id: 1,
      url: null,
    },
    pageHasToBePublished: {
      __typename: 'PageHasToBePublished',
      id: 1,
      isPublished: true,
      hasChanged: false,
      publishButtonIsPressed: false,
      hasParentProject: false,
      parentProjectIsPublished: false,
    },
    projectHasToBePublished: {
      __typename: 'ProjectHasToBePublished',
      id: 1,
      isPublished: true,
      hasChanged: false,
      publishButtonIsPressed: false,
    },
    dataHasChangedForSaving: {
      __typename: 'DataHasChangedForSaving',
      id: 1,
      pageType: null,
      isDataChanged: false,
      isSubmittedButtonPressed: false,
      isCancelButtonPressed: false,
      isDataPublished: false,
    },
    previewwWindow: {
      __typename: 'PreviewWindow',
      id: 1,
      isPreviewWindowOpen: false,
    },
    itemUrlWindow: {
      __typename: 'ItemUrlWindow',
      id: 1,
      openItemUrlWindow: false,
      itemPath: null,
    },
    pageNavigationLinks: {
      __typename: 'PageNavigationLinks',
      id: 1,
      prevLink: null,
      nextLink: null,
    },
    autosaveIndicator: {
      __typename: 'AutosaveIndicatorForHeader',
      id: 1,
      isSavingData: false,
      allSaved: false,
    },
    loadingIndicator: {
      __typename: 'LoadingIndicatorForOverlay',
      id: 2,
      isIndicatorLoading: false,
    },
    buttonDisabled: {
      __typename: 'ButtonDisabled',
      id: 1,
      isButtonDisabled: false,
    },
    buttonsDisabledOnAutosave: {
      __typename: 'ButtonsDisabledOnAutosave',
      id: 1,
      isButtonsDisabledOnAutosave: false,
    },
  },
});

// Install the Vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading',
  },
});
