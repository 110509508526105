import gql from 'graphql-tag';

export default gql`
query getIdBySlug(
  $path: String,
  $groupId: Int!
) {
  item_lookup(
   where: {
      slug_path: {_eq: $path},
      group_id: {_eq: $groupId}
   }
) {
    folder_id
    project_id
    page_id
    breadcrumbs
    item_path
    folder {
      name
    }
    project {
      name
      incompatible_item
      slug
    }
    page {
      name
      incompatible_item
      parent_project {
        incompatible_item
        name
        slug
      }
    }
    group {
      domain
    }
  }
}
`;
