import gql from 'graphql-tag';

export default gql`
{
  showHeader @client {
    id
    show
  }
}
`;
