import gql from 'graphql-tag';

export default gql`
{
  pageNavigationLinks @client {
    id
    prevLink
    nextLink
  }
}
`;
