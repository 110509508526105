import gql from 'graphql-tag';

export default gql`
query getLastGroupId($email: String!) {
  users(where: {email: {_eq: $email}}) {
    last_group_id
    home_group_id
  }
}
`;
