import Vue from 'vue';

const userRolePlugin = {
  install() {
    let currentRole = 'user';
    Object.defineProperty(Vue.prototype, '$currentUserRole', {
      get() {
        return currentRole;
      },
      set(newValue) {
        if (newValue === 'auditor') {
          currentRole = 'user';
        } else {
          currentRole = newValue;
        }
      },
    });
    Vue.prototype.$identifyAndSetCurrentRole = async (
      allRoles = [],
      roleIdsForGroup = [],
    ) => {
      console.warn('allRoles', allRoles);
      console.warn('rolesForGroup', roleIdsForGroup);

      let lowestLevelOfRole = 100; // set the default for now on the user key in the array
      roleIdsForGroup.forEach((userRoleId) => {
        const roleKeyInAllRoles = allRoles.find((role) => role.role_id === userRoleId);
        if (roleKeyInAllRoles !== -1 && roleKeyInAllRoles.level < lowestLevelOfRole) {
          lowestLevelOfRole = roleKeyInAllRoles.level;
        }
      });
      const lowestLevelRoleObject = allRoles.find((role) => role.level === lowestLevelOfRole);
      if (lowestLevelRoleObject === -1) {
        Vue.prototype.$currentUserRole = 'user';
        return 'user';
      }
      Vue.prototype.$currentUserRole = lowestLevelRoleObject.handle;
      return lowestLevelRoleObject.handle;
    };
  },
};

Vue.use(userRolePlugin);
