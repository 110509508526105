import gql from 'graphql-tag';

export default gql`
{
  dataHasChangedForSaving @client {
    id
    pageType
    isDataChanged
    isSubmittedButtonPressed
    isCancelButtonPressed
    isDataPublished
  }
}
`;
