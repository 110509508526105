import gql from 'graphql-tag';

export default gql`
{
  autosaveIndicator @client {
    id
    isSavingData
    allSaved
  }
}
`;
