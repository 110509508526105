import gql from 'graphql-tag';

export default gql`
{
  runBreadcrumbQuery @client {
    id
    runQuery
  }
}
`;
