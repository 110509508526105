import Vue from 'vue';

const privileges = {
  // eslint-disable-next-line no-unused-vars,no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$hasPrivileges = (
      usedPrivilege = null,
      isSuperAdmin = false,
      userPrivileges = null,
    ) => {
      if (isSuperAdmin) return true;
      if (!usedPrivilege || !userPrivileges) return false;
      return userPrivileges.includes(usedPrivilege);
    };
  },
};

Vue.use(privileges);
