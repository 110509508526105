import gql from 'graphql-tag';

export default gql`
{
  projectHasToBePublished @client {
    id
    isPublished
    hasChanged
    publishButtonIsPressed
  }
}
`;
