import gql from 'graphql-tag';

export default gql`
query getAllItemsOfTeamInGroup($teamIdArray: [Int!], $groupId: Int!) {
  teams_items(where: {
    team_id: { _in: $teamIdArray },
    _or: [
      { project: {group_id: { _eq: $groupId }}},
      { folder: {group_id: { _eq: $groupId }}}
    ]
  }
  ) {
    folder_id
    folder {
      folder_id
      name
      order_index
      slug
      root
      created_at
      updated_at
      lookup_entries {
        breadcrumbs
      }
    }
    project_id
    project {
      project_id
      name
      order_index
      slug
      created_at
      updated_at
      published
      searchable
      listed
      child_pages {
        published
        latest_page_version_id
        latest_published_page_version_id
      }
      projects_tags {
        tag {
          name
        }
      }
      lookup_entries {
        breadcrumbs
      }
    }
  }
}

`;
