import gql from 'graphql-tag';

export default gql`
{
  previewwWindow @client {
    id
    isPreviewWindowOpen
  }
}
`;
