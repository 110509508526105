var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: {
        show: _vm.$apollo.loading || _vm.loadingIndicator,
        rounded: "sm",
        variant: "dark"
      }
    },
    [
      _vm.useCrisp && !_vm.isMaintenance
        ? _c("useCrisp", {
            attrs: { email: _vm.userEmail, "group-name": _vm.groupName }
          })
        : _vm._e(),
      _c(
        "div",
        { class: _vm.pageType, attrs: { id: "app" } },
        [
          _vm.isMaintenance
            ? _c("maintenance-mode")
            : _vm.loading
            ? _c(
                "div",
                { attrs: { id: "content-wrapper" } },
                [
                  _vm.showHeader
                    ? _c("content-header", {
                        key: `header-${_vm.$route.fullPath}`
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { attrs: { id: "content" } },
                    [
                      _c("router-view", {
                        key: _vm.$route.fullPath,
                        on: { useCrisp: _vm.useCrispScript }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }