import gql from 'graphql-tag';

export default gql`
{
  buttonsDisabledOnAutosave @client {
    id
    isButtonsDisabledOnAutosave
  }
}
`;
