import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import ItemsWrapper from '@/views/ItemsWrapper.vue';
import Auth from '@aws-amplify/auth';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/not-in-allowed-roles',
    name: 'NotInAllowedRoles',
    component: () => import('@/views/NotInAllowedRoles.vue'),
  },
  {
    path: '/oops-something-went-wrong',
    name: 'SomeThingWentWrong',
    component: () => import('@/views/SomethingWentWrong.vue'),
  },
  {
    path: '/webmag-redirect-to-v1',
    name: 'RedirectToV1',
    component: () => import('@/views/RedirectToV1.vue'),
  },
  {
    path: '/webmag-deactivated',
    name: 'UserDeactivated',
    component: () => import('@/views/UserDeactivated.vue'),
  },
  {
    path: '/webmag-signup',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue'),
  },
  {
    path: '/webmag-verify',
    name: 'Verify',
    component: () => import('@/views/Verify.vue'),
  },
  {
    path: '/webmag-login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/webmag-password-reset',
    name: 'PasswordReset',
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    path: '/webmag-logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue'),
  },
  {
    path: '/webmag-setup',
    name: 'Setup',
    component: () => import('@/views/Setup.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/free-account-expired',
    name: 'FreeAccountExpired',
    component: () => import('@/views/FreeAccountExpired.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/no-group-or-team-dashboard',
    name: 'NoGroupOrTeamDashboard',
    component: () => import('@/views/NoGroupOrTeamDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/no-items-dashboard',
    name: 'NoItemsDashboard',
    component: () => import('@/views/NoItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account-management',
    name: 'GroupManagement',
    component: () => import('@/views/Group.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/team-management',
    name: 'TeamManagement',
    component: () => import('@/views/Team.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/personalization-management',
    name: 'PersonalizationManagement',
    component: () => import('@/views/Personalization.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/Reports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/form-management',
    name: 'FormManagement',
    component: () => import('@/views/Form.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'GeneralSettings',
    component: () => import('@/views/GeneralSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/404',
    component: () => import('@/components/NotFound.vue'),
  },
  {
    path: '/:parametersOfUrl*/folder-settings',
    name: 'FolderSettings',
    component: () => import('@/views/FolderSettings.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:parametersOfUrl+/project-settings',
    name: 'ProjectSettings',
    component: () => import('@/views/ProjectSettings.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:parametersOfUrl+/page-settings',
    name: 'PageSettings',
    component: () => import('@/views/PageSettings.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:parametersOfUrl*',
    name: 'ItemsWrapper',
    component: ItemsWrapper,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '*',
    component: () => import('@/components/NotFound.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      const currentSession = await Auth.currentSession();
      const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
      if (idTokenPayload['x-hasura-user-exists'] === 'false') {
        // if a user doesn't exist in hasura redirect to v1 screen
        // except if the user wants to access the setup component
        if (to.path !== '/webmag-setup') {
          next('/webmag-redirect-to-v1');
        } else {
          next();
        }
      } else {
        next();
      }
    } catch (e) {
      console.log('router error', e);
      next('/webmag-login');
    }
  }
  next();
});

export default router;
