import gql from 'graphql-tag';

export default gql`
{
  pageHasToBePublished @client {
    id
    isPublished
    hasChanged
    publishButtonIsPressed
    hasParentProject
    parentProjectIsPublished
  }
}
`;
